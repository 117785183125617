export const startToday = new Date(new Date().setHours(0, 0, 0, 0));
export const endToday = new Date(new Date().setHours(23, 59, 59, 0));

export const Ages = {
  today: endToday,
  tomorrow: new Date(new Date(endToday).setDate(endToday.getDate() + 1)),
  yesterday: new Date(new Date(startToday).setDate(startToday.getDate() - 1)),
  lastWeek: new Date(new Date(startToday).setDate(startToday.getDate() - 7)),
  nextWeek: new Date(new Date(endToday).setDate(endToday.getDate() + 7)),
  lastMonth: new Date(new Date(startToday).setMonth(startToday.getMonth() - 1)),
  nextMonth: new Date(new Date(endToday).setMonth(endToday.getMonth() + 1)),
  lastYear: new Date(new Date(startToday).setFullYear(startToday.getFullYear() - 1)),
  nextYear: new Date(new Date(endToday).setFullYear(endToday.getFullYear() + 1)),
  adult: new Date(new Date(endToday).setFullYear(endToday.getFullYear() - 18)),
  last10Years: new Date(new Date(endToday).setFullYear(endToday.getFullYear() - 10)),
  next10Years: new Date(new Date(endToday).setFullYear(endToday.getFullYear() + 10)),
  average: new Date(new Date(endToday).setFullYear(endToday.getFullYear() - 38)), // average age of users
  min: new Date(new Date(endToday).setFullYear(endToday.getFullYear() - 90))
};
